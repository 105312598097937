import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(pacienteId) {
		// const params = {
		// 	order: 'asc',
		// 	order_by: 'programmed_date',
		// 	offset: 0,
		// 	limit: 0,
		// }
		const { data } = await useJwt.get(
			`${API_ROUTES.assignMedicinePatient.get}/${pacienteId}`,
			// params,
		)

		return data
	},
	save(id, data) {
		return useJwt.put(`${API_ROUTES.assignMedicinePatient.save}/${id}`, data)
	},
	delete(id) {
		const url = API_ROUTES.assignMedicinePatient.delete
			.replace(':id', id)
		return useJwt.delete(url)
	},
	update(id, data) {
		return useJwt.update(`${API_ROUTES.assignMedicinePatient.update}/${id}`, data)
	},
}
